import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';

import { COUNTRY } from "./constants/country";
import SalesmanPanel from './components/SalesmanPanel';
import FinishedTransactions from './components/Transactions/FinishedTransactions';
import Settlement from './components/Transactions/Settlement';
import Invoices from './components/Transactions/Invoices';
import Integration from './components/Settings/Integration';
import SalesmanData from './components/Settings/SalesmanData';
import Users from './components/Settings/Users';
import Login from './components/Login';
import NotFound from './components/NotFound';
import ErrorPage from './components/ErrorPage';
import SessionError from './components/SessionError';
import PasswordReminder from './components/PasswordReminder';
import TransactionList from './components/OnlineTransactions/TransactionList';
import './components/utilities/css/font-awesome.min.css';
import './components/utilities/css/bootstrap4/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './components/utilities/css/style.css';
import 'react-dates/lib/css/_datepicker.css';
import ChooseMerchant from './components/ChooseMerchant';
import CorrectionListNew from "./components/pages/CorrectionList/CorrectionList";
import {DialogManager} from "./services/dialogService";
import {CustomPaypoTheme} from "./components/themes/CustomPaypoTheme";
import {ThemeProvider} from "@mui/material";
import {ToastContainer} from "react-toastify";
import P3GTransactionList from "./components/pages/transactions/P3GTransactionList/P3GTransactionList";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {useCookiebot} from './hooks/useCookiebot';
import CookieModal from './components/organisms/CookieModal';
import CookiebotStyle from './styles/cookiebotStyle';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
    const title = process.env.REACT_APP_COUNTRY === COUNTRY.ROMANIA ? 'PayPo.ro' : 'PayPo.pl';
    const queryClient = new QueryClient();

    useCookiebot(process.env.REACT_APP_COUNTRY === COUNTRY.POLAND);

    return (
        <>
            {
                process.env.REACT_APP_COUNTRY === COUNTRY.POLAND && (
                    <CookiebotStyle />
                )
            }

            <Helmet>
                <title>
                    { title }
                </title>
            </Helmet>

            <ToastContainer
                position="bottom-right"
            />

            <QueryClientProvider client={ queryClient }>
                <ThemeProvider theme={ CustomPaypoTheme }>
                    <DialogManager>
                        <BrowserRouter>
                            <div id='wrapper'>
                                <SentryRoutes>
                                    <Route
                                        index
                                        element={ <Login /> }
                                    />
                                    <Route
                                        path='/salesmanpanel'
                                        element={ <SalesmanPanel /> }
                                    />
                                    <Route
                                        path='/transactions/settlement'
                                        element={ <Settlement /> }
                                    />
                                    <Route
                                        path='/transactions/invoices'
                                        element={ <Invoices /> }
                                    />
                                    <Route
                                        path='/transactions/finishedtransactions'
                                        element={ <FinishedTransactions /> }
                                    />
                                    <Route
                                        path='/transactions/p3g'
                                        element={ <P3GTransactionList /> }
                                    />
                                    <Route
                                        path='/transactions/correction-list'
                                        element={ <CorrectionListNew /> }
                                    />
                                    <Route
                                        path='/setting/integration'
                                        element={ <Integration /> }
                                    />
                                    <Route
                                        path='/online-transactions/transactionlist'
                                        element={ <TransactionList /> }
                                    />
                                    <Route
                                        path='/setting/salesmandata'
                                        element={ <SalesmanData /> }
                                    />
                                    <Route
                                        path='/choose-merchant'
                                        element={ <ChooseMerchant /> }
                                    />
                                    <Route
                                        path='/setting/users'
                                        element={ <Users /> }
                                    />
                                    <Route
                                        path='/login/passwordReset/:id'
                                        element={ <PasswordReminder /> }
                                    />
                                    <Route
                                        path='/error'
                                        element={ <ErrorPage /> }
                                    />
                                    <Route
                                        path='/session-expired'
                                        element={ <SessionError /> }
                                    />
                                    <Route path='/.well-known/healthz' />
                                    <Route
                                        path='*'
                                        element={ <NotFound /> }
                                    />
                                </SentryRoutes>
                            </div>
                        </BrowserRouter>
                    </DialogManager>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>

            {
                process.env.REACT_APP_COUNTRY === COUNTRY.ROMANIA && (
                    <CookieModal />
                )
            }
        </>
    );
}

export default App;
